import React, { useEffect } from "react";
import { navigate } from "gatsby";

const CasestudiesPage = () => {
  useEffect(() => {
    if (window) {
      navigate("/case-studies");
    }
  });

  return null;
}

export default CasestudiesPage;
